import React, { useState, useRef } from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import Section from "../components/Section";
import SEO from "../components/SEO";
import { StyledProjectContainer } from "../styles/Containers";

export default function Workshops({ data, location }) {
  const block = data?.workshops.nodes[0];

  const {
    pdf: { pdf },
  } = data;

  return (
    <>
      <SEO
        title={block?.title}
        location={location}
        description={block?._rawBody[0].children[0].text}
      />
      <Section fullScreen>
        <StyledProjectContainer width="650px">
          <h1>{block?.title}</h1>
          <div className="header-underline"></div>
          <BlockContent
            blocks={block?._rawBody}
            projectId="hq5iqyv5"
            dataset="production"
          />
          <p>
            Check out our Apology Map worksheet{" "}
            <a href={pdf?.asset.url} target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </p>
        </StyledProjectContainer>
      </Section>
    </>
  );
}

export const workshopsQuery = graphql`
  query {
    workshops: allSanityWorkshops {
      nodes {
        title
        _rawBody
        _rawPdf
      }
    }
    pdf: sanityWorkshops {
      pdf {
        asset {
          url
        }
      }
    }
  }
`;
